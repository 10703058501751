import { useState, useMemo } from "react";
import { Box, Text, Flex } from "rebass";
import moment from "moment";

function Bar({ i, hover, color }) {
  return (
    <Box
      onMouseEnter={() => hover(i)}
      onMouseLeave={() => hover()}
      bg={color}
      height="35px"
      width="6px"
      m="2px"
      sx={{ borderRadius: "8px" }}
    />
  );
}

function Tooltip({ errors, total, hour, hovered, color }) {
  if (!hovered) return null;
  function getTimeWindow() {
    let splittedNumber = hour.split(":");
    let convertNumber = Number(splittedNumber[0]);
    let timeDifference = convertNumber - 1 < 0 ? 23 : convertNumber - 1;
    return `${timeDifference}:00 - ${hour}`;
  }
  const timeWindow = getTimeWindow();
  return (
    <Box
      color="#fff"
      bg="#95a5a6"
      width="140px"
      px="16px"
      py="8px"
      sx={{
        boxShadow: "0 2px 5px 0 rgba(0,0,0,.14), 0 2px 10px 0 rgba(0,0,0,.1)",
        opacity: hovered ? 1 : 0,
        zIndex: 1,
        position: "absolute",
        textAlign: "center",
        borderRadius: "16px",
        bottom: "-20%",
        left: "50%",
        border: `1px solid ${color}`,
        transform: "translate(-50%,100%)",
        ":after": {
          content: '""',
          position: "absolute",
          bottom: "100%",
          left: "50%",
          ml: "-5px",
          borderWidth: "5px",
          borderStyle: "solid",
          borderColor: `transparent transparent ${color} transparent`,
        },
      }}
    >
      <Text>{timeWindow}</Text>
      <Text>{`${errors} ${errors === 1 ? "issue" : "issues"}`}</Text>
      <Text>{`${Math.round(100 - (100 * errors) / total)}% uptime`}</Text>
    </Box>
  );
}

export default function Graph({ data, service, lastChecked }) {
  const [hoveredElement, setHoveredElement] = useState(null);

  function getColor(errors) {
    if (errors >= 2) return "#ff1744";
    if (errors === 1) return "#ffaf2d";
    return "#2ecc66";
  }

  function hover(index) {
    if (typeof index !== "number") setHoveredElement(null);
    else setHoveredElement(index);
  }

  const timeOfLastCheck = useMemo(() => {
    const getTimeDifference = () => {
      let now = moment(new Date());
      let check = moment(lastChecked);
      let resultInSec = now.diff(check, "seconds");
      let resultInMin = Math.round(resultInSec / 60);
      return `${
        resultInSec > 60 ? resultInMin + " min" : resultInSec + " seconds"
      }`;
    };
    return getTimeDifference();
  }, [lastChecked]);

  return (
    <Flex
      mb="24px"
      py="24px"
      px="48px"
      mx="5px"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      className="service-card"
    >
      <Box>
        <Text textAlign="center" as="h3" mb="8px">
          {service}
        </Text>
        {data ? (
          <Text
            textAlign="center"
            as="p"
            mb="24px !important"
            className="last-check"
          >
            {`Last check: ${timeOfLastCheck} ago`}
          </Text>
        ) : (
          <Text as="p" textAlign="center" className="status-notworking">
            Not enough data was provided to render a graph
          </Text>
        )}
      </Box>
      {data && (
        <Box>
          <Flex justifyContent="center">
            {data.map((statistics, i) => {
              const { total, errors, hour } = statistics;
              if (!total && !errors && !hour) return null;
              return (
                <Box key={`${i} ${hour}`} sx={{ position: "relative" }}>
                  <Bar i={i} hover={hover} color={getColor(errors)} />
                  <Tooltip
                    {...statistics}
                    hovered={hoveredElement === i}
                    color={getColor(errors)}
                  />
                </Box>
              );
            })}
          </Flex>
          <Text mt="8px" as="p" className="caption" textAlign="center">
            Last 24 hours
          </Text>
        </Box>
      )}
    </Flex>
  );
}
