import { Box } from "rebass";

export default function Spinner() {
  return (
    <Box
      sx={{
        display: "inline-block",
        width: "40px",
        height: "40px",

        ":after": {
          content: '""',
          display: "block",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          border: "3px solid #fff",
          borderColor: "#fff transparent #fff transparent",
          animation: "animate 1.2s linear infinite",
        },
        "@keyframes animate": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },
      }}
    />
  );
}
