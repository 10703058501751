import React from "react";
import { Route, Switch } from "react-router-dom";
import StatusView from "../modules/status/views/StatusView";

export default function Routes(props) {
  return (
    <Switch>
      <Route path="/">
        <StatusView {...props} />
      </Route>
    </Switch>
  );
}
