import React from "react";
import { Image, Text, Flex } from "rebass";
import getAppName from "utils/getAppName";

export default class Health extends React.Component {
  state = {
    status: null,
  };

  componentDidUpdate = () => {
    const { status } = this.state;
    const { latestStatistics } = this.props;
    if (!latestStatistics) return;
    let currentStatus = null;
    latestStatistics.forEach(({ data }) => {
      if (!data) return;
      if (currentStatus === "error") return;
      data.status === true ? (currentStatus = "ok") : (currentStatus = "error");
    });
    if (status !== currentStatus) {
      this.setState({ status: currentStatus });
    }
  };

  render() {
    const { status } = this.state;
    const { timeOfError } = this.props;
    const isWorking = status === "ok";
    const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
      .matches;
    return (
      <>
        <Image
          className="logo"
          mt="24px"
          src={`${process.env.PUBLIC_URL}/icons/${
            isDarkMode ? "dark" : "light"
          }-${getAppName("normalized")}.svg`}
        />
        <Text
          textAlign="center"
          mt="56px"
          as="h1"
        >{`${getAppName()} service status`}</Text>
        <Flex alignItems="center" mb="58px" minHeight="32px">
          {status && (
            <>
              <Image
                minWidth="28px"
                minHeight="28px"
                mr="6px"
                src={`${process.env.PUBLIC_URL}/icons/${
                  isWorking ? "ok" : "error"
                }.svg`}
              />
              <Text
                as="h2"
                className={isWorking ? "status-working" : "status-notworking"}
              >
                {isWorking ? (
                  "Services are operational"
                ) : (
                  <>
                    We are recognizing issues on our service <br />
                    {timeOfError && `since ${timeOfError}`}
                  </>
                )}
              </Text>
            </>
          )}
        </Flex>
      </>
    );
  }
}
