import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import isDev from "utils/isDev";
import Routes from "./routing/Routes";

export class App extends React.Component {
  state = {
    healthData: null,
    error: null,
    loading: true,
  };

  componentDidMount = async () => {
    try {
      const newState = await this.fetchData();
      this.setState({ healthData: newState });
    } catch (err) {
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  };

  fetchData = async () => {
    const response = await fetch(
      isDev() ? "http://192.168.5.11:3001/healthData" : "/healthData"
    );
    if (!response.ok) throw new Error("Fetch failed");
    const result = await response.json();
    return result;
  };

  render() {
    return (
      <Router>
        <Routes {...this.state} />
      </Router>
    );
  }
}

export default App;
