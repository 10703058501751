export default function getAppName(options) {
  const appName = process.env.REACT_APP_NAME || "bibino";
  switch (options) {
    case "upperCase":
      return appName.toUpperCase();
    case "lowerCase":
      return appName.toLowerCase();
    case "capital":
      return appName.charAt(0).toUpperCase() + appName.slice(1);
    case "trimmed":
      return appName.replace(/\s/g, "");
    case "normalized":
      return appName.replace(/\s/g, "").toLowerCase();
    default:
      return appName;
  }
}
